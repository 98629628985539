import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import Seo from "src/components/SEO"
import { PrimaryTitle } from "src/components/Typography"
import BaseContainer from "src/components/Base/BaseContainer"
import AchievementsTimeline from "src/components/AchievementsTimeline"

export const pageQuery = graphql`
  {
    allStrapiAchievements {
      nodes {
        title
        year
        month
        country {
          name
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 90
                height: 90
                quality: 90
                placeholder: NONE
                layout: FIXED
              )
            }
          }
        }
      }
    }
  }
`

const AchievementsPage = ({ data }) => {
  const [observed, setObserved] = useState(false)
  const achievements = data.allStrapiAchievements.nodes

  useEffect(() => {
    setObserved(true)
  }, [])

  return (
    <>
      <Seo
        title="Our achievements"
        description="We change the fate of animals in many countries. We work for systemic change.  Learn about our progress to date and our main achievements."
      />
      <PageHeader className={observed ? "-observed" : ""}>
        <PrimaryTitle as="h1">Our achievements</PrimaryTitle>
      </PageHeader>
      <BaseContainer>
        <AchievementsTimeline achievements={achievements} />
      </BaseContainer>
    </>
  )
}

const PageHeader = styled.header`
  padding-top: 180px;
  text-align: center;
  transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};

  &:not(.-observed) {
    opacity: 0;
    transform: translateY(20px);
  }

  ${media.tablet} {
    padding-top: 250px;
  }
`

export default AchievementsPage
