import React, { useState, useRef, useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import useIntersectionObserver from "src/hooks/useIntersectionObserver"
import { media } from "src/styles/breakpoints"
import { PopOut } from "src/styles/keyframes"
import { PrimaryTitle, TertiaryTitle } from "src/components/Typography"
import BaseFlagIcon from "src/components/Base/BaseFlagIcon"
import { slugify } from "src/utils/helpers"
import { get } from "lodash"

/* eslint-disable import/no-anonymous-default-export */
export default ({ achievements }) => {
  const [observed, setObserved] = useState(false)
  const wrapper = useRef()
  const mainLine = useRef()
  const eventRefs = []
  const items = restructureData()

  function restructureData() {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    const years = [...new Set(achievements.map((item) => item.year))]
      .sort()
      .reverse()
    const achievementsByDate = years.map((year) => {
      const yearItems = achievements.filter((item) => item.year === year)
      const monthItems = [...new Set(yearItems.map((item) => item.month))]
      monthItems.sort(function (a, b) {
        return monthNames.indexOf(b) - monthNames.indexOf(a)
      })
      const months = monthItems.map((month) => {
        const events = yearItems.filter((item) => item.month === month)
        return {
          month,
          events,
        }
      })
      return {
        year,
        months,
      }
    })
    return achievementsByDate
  }

  function setLineSize() {
    const height = wrapper.current.offsetHeight
    mainLine.current.style.maxWidth = height + "px"
  }

  useEffect(() => {
    setLineSize()
    setObserved(true)
  }, [])

  return (
    <Wrapper ref={wrapper} className={observed ? "-observed" : ""}>
      {items.map(({ year, months }, yearIndex) => (
        <React.Fragment key={yearIndex}>
          <Year>
            <YearInner>{year}</YearInner>
          </Year>
          {months.map(({ month, events }, monthIndex) => (
            <React.Fragment key={monthIndex}>
              <Month>
                <MonthInner>{month}</MonthInner>
              </Month>
              {events.map(({ title, country, image }, eventIndex) => {
                const [observed, setObserved] = useState(false)

                const eventRef = {
                  current: (eventRef) => (eventRefs[eventIndex] = eventRef),
                }

                useIntersectionObserver({
                  callback: (change) => {
                    if (change.isIntersecting) {
                      setObserved(true)
                    }
                  },
                  elementRef: eventRef,
                  options: {
                    rootMargin: "-100px",
                  },
                })

                return (
                  <Event
                    ref={eventRef}
                    className={observed ? "-observed" : ""}
                    key={eventIndex}
                  >
                    <EventContent>
                      <EventCircle>
                        <EventImage
                          image={get(
                            image,
                            "localFile.childImageSharp.gatsbyImageData"
                          )}
                          alt=""
                        />
                        {country !== "Anima International" && (
                          <EventCircleMark>
                            <EventCircleFlag country={slugify(country?.name)} />
                          </EventCircleMark>
                        )}
                      </EventCircle>
                      <EventTitle>
                        {title}
                        <EventCountry name={country?.name}>
                          {country?.name}
                        </EventCountry>
                      </EventTitle>
                    </EventContent>
                    <EventLine>
                      <LineShape x1="0" x2="100%" />
                    </EventLine>
                  </Event>
                )
              })}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
      <Line ref={mainLine}>
        <LineShape x1="0" x2="100%" />
      </Line>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: -110px;
  margin-bottom: 150px;
  position: relative;
  text-align: center;
  transition: 0.6s 0.15s ${(p) => p.theme.easing.easeOutQuart};

  &:not(.-observed) {
    opacity: 0;
    transform: translateY(20px);
  }

  ${media.tablet} {
    margin-top: -80px;
    margin-bottom: 215px;
  }
`

const Year = styled(PrimaryTitle)`
  margin: 170px 0 0;

  ${media.desktop} {
    position: sticky;
    top: 0;
    z-index: 2;
  }
`

const YearInner = styled.span`
  display: inline-block;
  min-width: 113px;
  padding: 50px 0;
  background: #fff;
`

const Month = styled(TertiaryTitle)`
  margin-top: 40px;
  margin-bottom: 0;

  ${media.desktop} {
    margin-top: 0;
    position: sticky;
    top: 100px;
    z-index: 2;
  }
`

const MonthInner = styled.span`
  display: inline-block;
  min-width: 113px;
  padding: 14px 0;
  position: relative;
  background: #fff;
`

const Line = styled.svg`
  display: none;
  width: 3000vw;
  max-width: none;
  height: 4px;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -1;
  transform: rotate(90deg);
  transform-origin: 0 50%;
  pointer-events: none;

  ${media.tablet} {
    display: block;
  }
`

const LineShape = styled.line`
  stroke: #81e2cc;
  stroke-width: 4;
  stroke-dasharray: 8 4;
`

const Event = styled.article`
  width: 290px;
  margin-top: 60px;
  position: relative;
  z-index: 1;

  &:nth-of-type(odd) {
    margin-left: -5px;
  }

  @media (min-width: 330px) {
    width: 320px;

    &:nth-of-type(odd) {
      margin-left: 5px;
    }
  }

  @media (min-width: 361px) {
    width: 320px;

    &:nth-of-type(odd) {
      margin-left: 15px;
    }
  }

  @media (min-width: 380px) {
    margin-left: auto;
    margin-right: auto;

    &:nth-of-type(odd) {
      margin-left: auto;
    }
  }

  ${media.tablet} {
    margin-top: 0;
    margin-left: calc(50% + 40px);

    &:nth-of-type(odd) {
      margin-left: calc(50% - 360px);
    }
  }

  ${media.desktop} {
    width: 400px;
    margin-left: calc(50% + 70px);

    &:nth-of-type(odd) {
      margin-left: calc(50% - 470px);
    }
  }

  ${media.desktopMedium} {
    margin-left: calc(50% + 140px);

    &:nth-of-type(odd) {
      margin-left: -20px;
    }
  }
`

const EventContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  text-align: left;
  transition: transform 0.25s;

  ${Event}:nth-of-type(odd) & {
    flex-direction: row-reverse;
    text-align: right;
  }

  ${media.pointer} {
    ${Event}:hover & {
      transform: translateX(7px);
    }

    ${Event}:nth-of-type(odd):hover & {
      transform: translateX(-7px);
    }
  }
`

const EventCircle = styled.div`
  height: 90px;
  position: relative;
  z-index: 1;
  transform: scale(0);

  ${Event}.-observed & {
    animation: ${PopOut} 1.5s 0.4s ${(p) => p.theme.easing.easeOutQuart};
    animation-fill-mode: forwards;
  }
`

const EventImage = styled(GatsbyImage)`
  width: 90px;
  min-width: 90px;
  height: 90px;

  &,
  img {
    border-radius: 50%;
  }
`

const EventCircleMark = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  position: absolute;
  right: -10px;
  bottom: -15px;
  background: #fff;
  box-shadow: 0 16px 16px rgba(0, 11, 33, 0.08);
  border-radius: 50%;
`

const EventCircleFlag = styled(BaseFlagIcon)`
  width: 28px;
`

const EventTitle = styled.p`
  width: 180px;
  position: relative;
  font-size: 13px;
  line-height: 1.5;
  color: ${(p) => p.theme.color.textLight};
  transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};
  transition-property: opacity, transform;

  /* 'article' is needed, otherwise prettier goes crazy */
  article${Event}:not(.-observed) & {
    opacity: 0;
    transform: translateY(20px);
  }

  ${Event}.-observed & {
    transition-delay: 0.4s;
  }

  @media (min-width: 330px) {
    width: 210px;
  }

  ${media.desktop} {
    width: 280px;
    font-size: 15px;
    line-height: inherit;
  }
`

const EventCountry = styled.small`
  font-size: 12px;
  position: absolute;
  left: 0;
  top: 100%;
  text-transform: ${(p) => (p.name === "uk" ? "uppercase" : "capitalize")};
  font-weight: 500;
  color: #000;
  transition: opacity 0.15s;

  ${Event}:nth-of-type(odd) & {
    left: auto;
    right: 0;
  }

  ${media.pointer} {
    opacity: 0;

    ${Event}:hover & {
      opacity: 1;
    }
  }
`

const EventLine = styled(Line)`
  width: calc(30px - 5px + 7px);
  top: calc(50% - 2px);
  left: calc(-40px + 5px);
  z-index: 0;
  transform: none;
  transition: 0.5s ${(p) => p.theme.easing.easeInOutQuart};
  transition-property: width, right;

  ${Event}:nth-of-type(odd) & {
    left: calc(360px - 3px);
    transform: rotate(180deg);
  }

  ${Event}:not(.-observed) & {
    width: 0;
    right: 3px;
  }

  ${media.desktop} {
    width: calc(80px - 5px + 7px);
    left: calc(-70px + 5px);

    ${Event}:nth-of-type(odd) & {
      left: calc(470px - 3px);
    }
  }

  ${media.desktopMedium} {
    width: calc(140px - 5px + 7px);
    left: calc(-140px + 5px);

    ${Event}:nth-of-type(odd) & {
      left: calc(540px - 3px);
    }
  }
`
